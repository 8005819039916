<!--wordcloud-->
<template>
  <div class="flex h-full overflow-hidden">
    <el-container class="flex flex-1 relative">
      <!-- Left sidebar -->
      <el-aside v-show="showDataPanel" class="w-72 transition-all overflow-y-auto border-r border-gray-300 custom-scrollbar">
        <!-- Left sidebar content -->
        <div class="p-4">
          <p>
            <el-button type="warning" @click="debouncePrice" class="rounded-full">{{ $t('Price') }}</el-button>
          </p>
          <h2 class="text-lg font-semibold">{{ $t('Data') }}</h2>
          <el-input 
            v-model="textToAnalyze"
            :placeholder="$t('LongText')"
            class="w-full mt-2"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            @input="debouncedAnalyze"
            clearable
          ></el-input>
          <el-button type="success" class="mt-2 rounded-full" @click="analyzeText">{{ $t('Analyze') }}</el-button>

          <el-input v-model="key" :placeholder="$t('Enter key')" class="w-full mt-2" clearable></el-input>
          <el-input v-model="value" :placeholder="$t('Enter value')" class="w-full mt-2" clearable></el-input>
          <el-button type="primary" class="mt-2 rounded-full" @click="addData" round>{{ $t('Add Data') }}</el-button>
        </div>
        <hr>
        <div class="p-4">
          <el-scrollbar max-height="50vh" class="custom-scrollbar">
            <el-table
              :data="processedChartData" 
              :key="tableKey"
              class="w-full"
              v-loading="loading"
            >
              <el-table-column prop="name" :label="$t('Name')" />
              <el-table-column prop="value" :label="$t('Value')" />
              <el-table-column :label="$t('Operations')" width="180">
                <template #default="scope">
                  <el-button circle type="danger" @click="deleteData(scope.$index)">
                    <el-icon><Delete /></el-icon>
                  </el-button>                  
                </template>
              </el-table-column>
            </el-table>
          </el-scrollbar>
        </div>
        <hr>
        <div class="p-4">
          <el-select v-model="selectedLang" @change="changeLang" placeholder="Select Language" class="w-full">
            <el-option v-for="(item, index) in Object.values($i18n.messages.tag_label)" :key="index" :label="item"
              :value="$i18n.messages.label_tag[item]"></el-option>
          </el-select>
          <p class="mt-4">
            <el-button plain @click="serviceVisible = true" class="rounded-full">{{ $t('Service') }}</el-button>
          </p>
          <p class="mt-4 text-sm text-gray-500">
            Copyright &copy; emperinter
            <br>
            <a href="/sitemap.xml" target="_blank">sitemap</a>
            <br>
            <a href="/blog" target="_blank">Blog</a>
            <br>
            <a href="https://apps.apple.com/us/app/wordcloudstudio-wordart-tool/id6504160406" target="_blank">WordCloudStudio</a>
          </p>
        </div>
      </el-aside>

      <!-- Toggle button for left sidebar -->
      <div class="absolute top-1/2 transform -translate-y-1/2 z-50 bg-white border border-gray-300 p-1 cursor-pointer transition-all rounded-full" :class="{ 'left-0': !showDataPanel, 'left-72': showDataPanel }" @click="toggleDataPanel">
        <el-icon><ArrowLeft v-if="showDataPanel" /><ArrowRight v-else /></el-icon>
      </div>

      <!-- Main content area -->
      <el-main class="flex-1 overflow-hidden">
        <!-- WordCloud -->
        <div class="flex flex-col h-full bg-blue-100 bg-grid overflow-hidden">
          <div class="flex-1 flex justify-center items-center overflow-auto" ref="editorContent">
            <div class="content-wrapper" :style="contentWrapperStyle">
              <div v-if="isSubscribed" ref="chart" class="shadow-md" :style="{ backgroundColor, width: `${imageWidth}px`, height: `${imageHeight}px` }"></div>
              <!-- Carousel for non-subscribed users -->
              <el-carousel v-else :interval="5000" arrow="always" class="shadow-md overflow-hidden" :style="{ width: '400px', height: '325px' }">
                <el-carousel-item v-for="(image, index) in imageUrls" :key="index">
                  <div class="w-full h-full flex items-center justify-center" style="background-color: #ffffff;">
                    <img :src="require(`@/assets/${image}`)" :alt="image" style="max-width: 100%; max-height: 100%; width: auto; height: auto; object-fit: contain;">
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
          <div class="flex justify-center items-center p-2 gap-2 bg-blue-50 border-t border-blue-200">
            <el-button circle @click="zoomOut" class="rounded-full">
              <el-icon><Remove /></el-icon>
            </el-button>
            <el-button circle @click="zoomIn" class="rounded-full">
              <el-icon><Plus /></el-icon>
            </el-button>
            <el-button circle @click="resetZoom" class="rounded-full">
              <el-icon><Refresh /></el-icon>
            </el-button>
            <span class="text-sm text-gray-600">{{ Math.round(zoomLevel * 100) }}%</span>
          </div>
        </div>
      </el-main>


      <!-- Toggle button for right sidebar -->
      <div class="absolute top-1/2 transform -translate-y-1/2 z-50 bg-white border border-gray-300 p-1 cursor-pointer transition-all rounded-full" :class="{ 'right-0': !showOptionsPanel, 'right-72': showOptionsPanel }" @click="toggleOptionsPanel">
        <el-icon><ArrowRight v-if="showOptionsPanel" /><ArrowLeft v-else /></el-icon>
      </div>

      <!-- Right sidebar -->
      <el-aside v-show="showOptionsPanel" class="w-72 transition-all overflow-y-auto border-l border-gray-300 custom-scrollbar">
        <!-- Right sidebar content -->
        <el-scrollbar class="h-full p-4 custom-scrollbar">
          <div>
            <h1 class="text-lg font-semibold">{{ $t('Options') }}</h1>
            <el-button type="success" class="mt-2 rounded-full" @click="exportLayout" round>
              {{ $t('OutPut') }}<el-icon class="el-icon--right">
                <Upload />
              </el-icon>
            </el-button>
            <el-divider></el-divider>

            <h2 class="text-lg font-semibold">{{ $t('Mask') }}</h2>
            <el-button type="warning" class="mt-2 rounded-full" @click="maskVisible = true">{{ $t('Masks') }}</el-button>
            
            <el-divider></el-divider>
            <h2 class="text-lg font-semibold">{{ $t('Image') }}</h2>
            <p>{{ $t('ImageSize') }}</p>
            <el-select v-model="selectedSize" @change="updateImageSize" placeholder="Select Size" class="w-full mt-2">
              <el-option
                v-for="size in defaultSizes"
                :key="size.tag"
                :label="size.tag"
                :value="size"
              ></el-option>
            </el-select>            
            <p class="mt-2">{{ $t('imageWidth') }}</p>
            <el-input-number v-model="imageWidth" :min="10" :max="10000" :label="$t('imageWidth')" class="w-full mt-2"></el-input-number>
            <p class="mt-2">{{ $t('imageHeight') }}</p>
            <el-input-number v-model="imageHeight" :min="10" :max="10000" :label="$t('imageHeight')" class="w-full mt-2"></el-input-number>
            <p class="mt-2">{{ $t('backgroundColor') }}</p>
            
            <el-color-picker v-model="backgroundColor" show-alpha :predefine="predefineColors"
              :label="$t('Background Color')" size="large" class="w-full mt-2"></el-color-picker>
            <el-divider></el-divider>

            <h2 class="text-lg font-semibold">{{ $t('FontSet') }}</h2>
            <p class="mt-2">{{ $t('Font') }}</p>
            <el-select v-model="selectedFont" :placeholder="$t('SelectFont')" class="w-full mt-2">
              <el-option
                v-for="font in fontOptions"
                :key="font.value"
                :label="font.label"
                :value="font.value"
              ></el-option>
            </el-select>            
            <p class="mt-2">{{ $t('minSize') }}</p>
            <el-input-number v-model="minSize" :min="10" :max="10000" :label="$t('minSize')" class="w-full mt-2"></el-input-number>
            <p class="mt-2">{{ $t('maxSize') }}</p>
            <el-input-number v-model="maxSize" :min="10" :max="10000" :label="$t('maxSize')" class="w-full mt-2"></el-input-number>
            <el-divider></el-divider>
          </div>
        </el-scrollbar>
      </el-aside>
    </el-container>

    <el-dialog v-model="maskVisible" :title="$t('Masks')" width="500" :before-close="handleClose">
      <div v-if="this.hasPurchased()">
        <SelectMask @mask-selected="handleMaskSelected" />
      </div>
      <div v-else>
        <el-tabs :tab-position="tabPosition" label-width="250px">
          <el-tab-pane :label="$t('Plan')">
            <template #label>
              <el-icon><PriceTag /></el-icon>
              {{ $t('Plan') }}
            </template>
            <PricePage />
          </el-tab-pane>
          <span></span>
          <el-tab-pane :label="$t('Account')">
            <template #label>
              <el-icon><User /></el-icon>
              {{ $t('Account') }}
            </template>
            <Account />
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>


    <el-dialog v-model="priceVisible" :title="$t('')" width="500" :before-close="handleClose">
      <el-tabs :tab-position="tabPosition" label-width="250px">
        <el-tab-pane :label="$t('Plan')">
          <template #label>
            <el-icon><PriceTag /></el-icon>
            {{ $t('Plan') }}
          </template>
          <PricePage />
        </el-tab-pane>
        <span></span>
        <el-tab-pane :label="$t('Account')">
          <template #label>
            <el-icon><User /></el-icon>
            {{ $t('Account') }}
          </template>
          <Account />
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <el-dialog v-model="serviceVisible" :title="$t('Service')" width="500" :before-close="handleClose">
      <Service />
    </el-dialog>
  </div>
</template>

<style scoped>
.bg-grid {
  background-image: linear-gradient(#ffffff 2px, transparent 2px),
    linear-gradient(90deg, #ffffff 2px, transparent 2px);
  background-size: 10px 10px;
}

/* 美化滚动条 */
.custom-scrollbar {
  overflow-y: auto; /* 允许垂直滚动 */
  scrollbar-width: thin; /* Firefox 滚动条为细 */
  scrollbar-color: rgba(136, 136, 136, 0.5) transparent; /* 半透明滚动条颜色和透明轨道颜色 */
}

/* 隐藏滚动条 */
.custom-scrollbar::-webkit-scrollbar {
  width: 0; /* 默认隐藏滚动条 */
}

.custom-scrollbar:hover::-webkit-scrollbar {
  width: 4px; /* 鼠标悬停时显示滚动条 */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent; /* 轨道颜色透明 */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.5); /* 半透明滚动条颜色 */
  border-radius: 2px; /* 圆角 */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(136, 136, 136, 0.8); /* 悬停时颜色稍深 */
}
</style>

<script>
import * as echarts from 'echarts';
import 'echarts-wordcloud';
import { ElInput, ElButton, ElInputNumber, ElColorPicker, ElNotification } from 'element-plus';
import { Upload, Remove, Plus, Refresh, Delete, ArrowLeft, ArrowRight, PriceTag, User } from '@element-plus/icons-vue';
import SelectMask from './SelectMask.vue';
import { ref } from 'vue'
import html2canvas from 'html2canvas';
import PricePage from './PricePage.vue';
import Service from './Service.vue';
import Account from './Account.vue';
import dogImg from '../assets/love.png';
import FontFaceObserver from 'fontfaceobserver';
import { debounce } from 'lodash-es'
import { CONFIG_GOOGLE_FONTS } from '@/config';

const GOOGLE_FONTS = CONFIG_GOOGLE_FONTS;

export default {
  name: 'HomePage',
  components: {
    ElInput,
    ElButton,
    ElInputNumber,
    ElColorPicker,
    SelectMask,
    Upload,
    PricePage,
    Service,
    Account,
    Remove,
    Plus,
    Refresh,
    Delete,
    ArrowLeft,
    ArrowRight,
    PriceTag,
    User
  },
  setup() {
    // 使用ref创建响应式变量
    const chart = ref(null);
    const predefineColors = ref([
      '#ff4500',
      '#ff8c00',
      '#ffd700',
      '#90ee90',
      '#00ced1',
      '#1e90ff',
      '#c71585',
      'rgba(255, 69, 0, 0.68)',
      'rgb(255, 120, 0)',
      'hsv(51, 100, 98)',
      'hsva(120, 40, 94, 0.5)',
      'hsl(181, 100%, 37%)',
      'hsla(209, 100%, 56%, 0.73)',
      '#c7158577',
    ]);

    return {
      chart,
      predefineColors
    }
  },
  data() {
    return {
      chartData: [],
      key: '',
      value: '',
      editingIndex: -1,
      backgroundColor: '#FFFFFF',
      maskVisible: false,
      imageHeight: 512,
      imageWidth: 512,
      priceVisible: false,
      serviceVisible: false,
      selectedLang: null,
      selectedMask: '',
      isSubscribed: false,
      minSize: 5,
      maxSize: 1000,
      selectedFont: '',
      fontOptions: [],
      loading: false,
      tableKey: 0,
      textToAnalyze: '',
      selectedSize: null,
      imageUrls: [
        'cat.png',
        'flight.png',
        'flower.png',
        'football.png',
        'star.png',
      ],    
      defaultSizes: [
        { width: 800, height: 600, tag: "5 inch" },
        { width: 1024, height: 768, tag: "6 inch" },
        { width: 1600, height: 1200, tag: "10 inch" },
        { width: 2048, height: 1536, tag: "20 inch" },
        { width: 2560, height: 1440, tag: "QHD" },
        { width: 3840, height: 2160, tag: "4K UHD" },
        { width: 7680, height: 4320, tag: "8K UHD" },
        { width: 320, height: 240, tag: "QVGA" },
        { width: 640, height: 480, tag: "VGA" },
        { width: 1280, height: 720, tag: "HD" },
        { width: 1920, height: 1080, tag: "Full HD" },
        { width: 2560, height: 1600, tag: "WQXGA" },
        { width: 3440, height: 1440, tag: "UWQHD" },
        { width: 5120, height: 2880, tag: "5K" },
        { width: 6016, height: 3384, tag: "6K" },
        { width: 1240, height: 1754, tag: "A6" },
        { width: 1748, height: 2480, tag: "A5" },
        { width: 2480, height: 3508, tag: "A4" },
        { width: 3508, height: 4961, tag: "A3" },
        { width: 4961, height: 7016, tag: "A2" },
        { width: 7016, height: 9933, tag: "A1" },
        { width: 9933, height: 14043, tag: "A0" },
        { width: 500, height: 500, tag: "500 x 500" },
        { width: 1000, height: 1000, tag: "1000 x 1000" },
        { width: 1500, height: 1500, tag: "1500 x 1500" },
        { width: 2000, height: 2000, tag: "2000 x 2000" },
        { width: 2500, height: 2500, tag: "2500 x 2500" },
        { width: 3000, height: 3000, tag: "3000 x 3000" }
      ],      
      zoomLevel: 1,
      contentPosition: { x: 0, y: 0 },
      showDataPanel: true,
      showOptionsPanel: true,
      fontsLoaded: false,
      // 新增：缓存ECharts实例
      chartInstance: null,
      // 新增：跟踪更新状态
      updatePending: false
    };
  },
  created() {
    // 优化：使用防抖处理所有会触发图表更新的监听器
    const debouncedUpdate = debounce(this.updateChart, 200);
    
    const watchProperties = ['imageHeight', 'imageWidth', 'minSize', 'maxSize', 'chartData', 'selectedFont', 'selectedSize', 'backgroundColor', 'selectedMask'];
    
    watchProperties.forEach(prop => {
      this.$watch(prop, () => {
        if (!this.updatePending) {
          this.updatePending = true;
          requestAnimationFrame(() => {
            debouncedUpdate();
            this.updatePending = false;
          });
        }
      });
    });

    this.debouncedAnalyze = debounce(this.analyzeText, 300);
    this.debouncePrice = debounce(() => {
      this.priceVisible = true;
    }, 300);
  },
  computed: {
    isInputValid() {    
      return this.key.trim() !== '' && this.value.trim() !== '';
    },
    editorContentStyle() {
      return {
        transform: `scale(${this.zoomLevel})`,
        transformOrigin: 'top left',
      };
    },
    contentWrapperStyle() {
      return {
        transform: `scale(${this.zoomLevel}) translate(${this.contentPosition.x}px, ${this.contentPosition.y}px)`,
        transformOrigin: 'center',
      };
    },
    processedChartData() {
      // 优化：限制数据量
      return this.chartData.slice(0, 1000);
    }
  },
  mounted() {
    this.selectedLang = this.$i18n.locale;
    this.generateData();
    this.loadFonts();
    this.hasPurchased();
    this.initChart();
  },
  methods: {
    toggleDataPanel() {
      this.showDataPanel = !this.showDataPanel;
    },
    toggleOptionsPanel() {
      this.showOptionsPanel = !this.showOptionsPanel;
    },    
    zoomIn() {
      this.zoomLevel = Math.min(this.zoomLevel * 1.2, 3);
    },
    zoomOut() {
      this.zoomLevel = Math.max(this.zoomLevel / 1.2, 0.1);
    },
    resetZoom() {
      this.zoomLevel = 1;
    },    
    changeLang(lang) {
      this.$i18n.locale = lang;
    },
    updateImageSize(size) {
      this.imageWidth = size.width;
      this.imageHeight = size.height;
    },    
    async loadFonts() {
      try {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        const fontFamilies = GOOGLE_FONTS.map(font => font.value.replace(/ /g, '+')).join('&family=');
        link.href = `//fonts.googleapis.com/css2?family=${fontFamilies}&display=swap`;
        document.head.appendChild(link);

        this.fontOptions = GOOGLE_FONTS;
        
        if (!this.selectedFont) {
          this.selectedFont = 'Roboto';
        }
        
        await this.loadSelectedFont(this.selectedFont);
        this.fontsLoaded = true;
      } catch (error) {
        console.error('Font loading failed:', error);
        this.selectedFont = 'Roboto';
      }
    },

    async loadSelectedFont(fontFamily) {
      try {
        const font = new FontFaceObserver(fontFamily);
        await Promise.race([
          font.load(null),
          new Promise((_, reject) => 
            setTimeout(() => reject(new Error('Font loading timeout')), 3000)
          )
        ]);
      } catch (error) {
        console.error(`Font ${fontFamily} loading failed:`, error);
        this.selectedFont = 'Roboto';
      }
    },

    initChart() {
      if (this.chartInstance) {
        this.chartInstance.dispose();
      }

      const canvas = document.createElement('canvas');
      canvas.getContext('2d', { willReadFrequently: true });
      
      this.chartInstance = echarts.init(this.$refs.chart, null, {
        renderer: 'canvas',
        width: this.imageWidth,
        height: this.imageHeight
      });

      // 优化：使用RAF处理重绘
      this.chartInstance.on('finished', () => {
        requestAnimationFrame(() => {
          if (this.updatePending) {
            this.updateChart();
          }
        });
      });

      const baseOption = this.getChartOption();
      this.loadMaskImage(baseOption);
    },

    getChartOption() {
      return {
        series: [{
          type: 'wordCloud',
          sizeRange: [this.minSize, this.maxSize],
          rotationRange: [-90, 90],
          rotationStep: 45,
          gridSize: 8,
          drawOutOfBound: false,
          shrinkToFit: true,
          keepAspect: false,
          layoutAnimation: true,
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          right: 0,
          bottom: 0,
          textStyle: {
            fontFamily: this.selectedFont,
            fontWeight: 'bold',
            color: () => {
              const colors = ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd'];
              return colors[Math.floor(Math.random() * colors.length)];
            }
          },
          data: this.processedChartData
        }]
      };
    },

    loadMaskImage(option) {
      const maskImage = new Image();
      maskImage.onload = () => {
        option.series[0].maskImage = maskImage;
        if (this.chartInstance) {
          this.chartInstance.setOption(option);
        }
      };
      maskImage.src = this.selectedMask || dogImg;
    },

    Notify(type = 'warning', title = this.$t("Warning"), message = this.$t("InvalidInput")) {
      ElNotification({
        title,
        message,
        position: 'top-left',
        type
      });
    },

    async analyzeText() {
      if (!this.hasPurchased()) {
        this.priceVisible = true;
        return;
      }

      if (!this.textToAnalyze?.trim()) {
        return;
      }

      this.loading = true;
      try {
        const words = this.textToAnalyze.split(/\s+/);
        const wordCount = {};

        words.forEach(word => {
          if (word) {
            wordCount[word] = (wordCount[word] || 0) + 1;
          }
        });

        this.chartData = Object.entries(wordCount)
          .map(([name, value]) => ({ name, value }))
          .slice(0, 1000); // 限制数据量

        this.textToAnalyze = '';
        this.tableKey++;
      } catch (error) {
        console.error('Text analysis error:', error);
        this.Notify('error', this.$t('Error'), error.message);
      } finally {
        this.loading = false;
      }
    },

    hasPurchased() {
      const purchaseStatus = localStorage.getItem("purchaseStatus");
      const expirationTime = localStorage.getItem("expirationTime");
      const hasPurchased = purchaseStatus === "purchased" && expirationTime && new Date() < new Date(expirationTime);
      this.isSubscribed = hasPurchased;
      return hasPurchased;
    },

    async updateChart() {
      if (!this.$refs.chart || !this.hasPurchased()) {
        if (!this.hasPurchased()) {
          this.imageHeight = 512;
          this.imageWidth = 512;
          this.priceVisible = true;
        }
        return;
      }

      try {
        await this.loadSelectedFont(this.selectedFont);

        if (!this.chartInstance) {
          this.initChart();
          return;
        }

        const option = this.getChartOption();
        this.loadMaskImage(option);
      } catch (error) {
        console.error('Chart update error:', error);
        this.Notify('error', this.$t('Error'), error.message);
      }
    },

    generateData() {
      const baseData = [
        { name: '❤️', value: 888 },
        { name: 'WordCloud', value: 500 },
        { name: 'سحابة الكلمات', value: 100 },
        { name: 'Облак на думи', value: 100 },
        { name: 'Núvol de paraules', value: 100 },
        { name: 'Oblak riječi', value: 100 },
        { name: 'Slovní mrak', value: 100 },
        { name: 'Ordmoln', value: 100 },
      ];

      // 限制生成的数据量
      const maxDataPoints = 100;
      this.chartData = baseData.concat(
        Array.from({ length: Math.min(maxDataPoints - baseData.length, 90) }, (_, i) => ({
          name: `${i + 1}`,
          value: Math.floor(Math.random() * 51) + 50
        }))
      );
    },

    addData() {
      if (!this.hasPurchased()) {
        this.priceVisible = true;
        return;
      }

      if (!this.isInputValid) {
        this.Notify();
        return;
      }

      if (this.editingIndex > -1) {
        this.chartData[this.editingIndex] = { name: this.key, value: this.value };
        this.editingIndex = -1;
      } else {
        this.chartData.push({ name: this.key, value: this.value });
      }

      this.key = '';
      this.value = '';
    },

    editData(index) {
      this.editingIndex = index;
      this.key = this.chartData[index].name;
      this.value = this.chartData[index].value;
    },

    deleteData(index) {
      this.chartData.splice(index, 1);
    },

    handleMaskSelected(mask) {
      this.selectedMask = mask;
      this.maskVisible = false;
    },

    exportLayout() {
      if (!this.$refs.chart) return;

      html2canvas(this.$refs.chart, {
        backgroundColor: this.backgroundColor,
        logging: false,
        useCORS: true,
        allowTaint: true
      }).then(canvas => {
        const dataURL = canvas.toDataURL('image/png');
        const a = document.createElement('a');
        a.href = dataURL;
        const date = new Date();
        const dateString = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
        a.download = `wordcloud_${dateString}.png`;
        a.click();
      });
    }
  },
  beforeUnmount() {
    if (this.chartInstance) {
      this.chartInstance.dispose();
      this.chartInstance = null;
    }
    this.debouncedAnalyze.cancel();
    this.debouncePrice.cancel();
  }
};
</script>