<template>
  <div class="container">
    <!-- 搜索框 -->
    <el-input v-model="keyword" @keyup.enter="searchMasks" :placeholder="$t('Enter keyword')" class="search-input"></el-input>

    <!-- 进度展示 -->
    <el-progress v-if="loading" :percentage="progress" color="#409EFF" class="progress-bar"></el-progress>

    <!-- 口罩列表 -->
    <el-row class="mask-list" v-show="!loading && isSubscriber">
      <el-col :span="8" v-for="mask in masks" :key="mask.id" @click="selectMask(mask.mask)">
        <div class="mask-item">
          <img :src="mask.pre_image_remote_url" alt="Mask" class="mask-image">
        </div>
      </el-col>
    </el-row>

    <!-- 未订阅用户提示 -->
    <div v-if="!isSubscriber">
      <h1>
        Please subscribe to access the content.
      </h1>
    </div>

    <!-- 分页按钮 -->
    <el-button @click="prevPage" :disabled="currentPage === 1 || loading || !isSubscriber" class="page-btn">{{ $t('Previous Page') }}</el-button>
    <el-button @click="nextPage" :disabled="currentPage >= totalPages || loading || !isSubscriber" class="page-btn">{{ $t('Next Page') }}</el-button>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      masks: [], // 存储口罩数据
      currentPage: 1, // 当前页数
      pageSize: 6, // 每页显示数量
      totalPages: 0, // 总页数
      keyword: '', // 搜索关键字
      loading: false, // 是否正在加载数据
      progress: 0, // 加载进度
      isSubscriber: false, // 是否为订阅用户
    };
  },
  methods: {
    hasPurchased() {
      const purchaseStatus = localStorage.getItem("purchaseStatus");
      const expirationTime = localStorage.getItem("expirationTime");
      const hasPurchased = purchaseStatus === "purchased" && expirationTime && new Date() < new Date(expirationTime);
      if (hasPurchased){
        this.isSubscriber = true;
        return hasPurchased;
      }else{
        this.isSubscriber = false;
        return hasPurchased;
      }
    },
    // 发起 HTTP 请求获取口罩数据
    generateToken() {
        const timestamp = Math.floor(Date.now() / 1000);
        const randomValue = crypto.randomUUID();
        const secretKey = '1abff3a1d9d11568ad55d4b458c6f7fe';
        
        const message = `${timestamp}.${randomValue}`;
        const encoder = new TextEncoder();
        const messageData = encoder.encode(message);
        const keyData = encoder.encode(secretKey);
        
        return crypto.subtle.importKey(
          'raw',
          keyData,
          { name: 'HMAC', hash: 'SHA-256' },
          false,
          ['sign']
        ).then(key => {
          return crypto.subtle.sign(
            'HMAC',
            key,
            messageData
          );
        }).then(signature => {
          const hashArray = Array.from(new Uint8Array(signature));
          const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
          return `${randomValue}.${timestamp}.${hashHex}`;
        });
      },
      async fetchMasks() {
      try {
        this.loading = true;
        if (!this.hasPurchased()) {
          this.isSubscriber = false;
          return;
        }

        const progressInterval = setInterval(() => {
          this.progress = Math.min(this.progress + 10, 95);
        }, 500);

        const token = await this.generateToken();
        
        const response = await axios({
          method: 'post',
          url: '/wordcloudmaster/search',
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            query: this.keyword,
            page: this.currentPage,
            page_size: this.pageSize,
            token: token
          }
        });

        clearInterval(progressInterval);
        this.progress = 100;

        if (response.data?.data) {
          this.masks = response.data.data.masks;
          this.totalPages = response.data.data.total_page;
          this.isSubscriber = true;
        }

      } catch (error) {
        console.error('Error fetching masks:', error);
      } finally {
        this.loading = false;
        setTimeout(() => {
          this.progress = 0;
        }, 200);
      }
    },
    // 搜索口罩
    searchMasks() {
      this.currentPage = 1; // 搜索时重置当前页数为第一页
      this.fetchMasks();
    },
    // 翻到上一页
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchMasks();
      }
    },
    // 翻到下一页
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchMasks();
      }
    },
    // 选择口罩，并传递给其他组件
    selectMask(mask) {
      // 选定口罩时将口罩数据赋值给 selectedMask 变量
      this.selectedMask = mask;
      // 将选定的口罩数据传递给父组件
      this.$emit('mask-selected', mask);
    }
  },
  mounted() {
    this.hasPurchased();
    // 在组件挂载后调用 fetchMasks 方法获取口罩数据
    this.fetchMasks();
  }
};
</script>

<style scoped>
.container {
  padding: 20px;
}

.search-input {
  margin-bottom: 20px;
}

.mask-list {
  margin-bottom: 20px;
}

.mask-item {
  text-align: center;
  margin-bottom: 10px;
}

.mask-image {
  width: 150px;
  height: 150px;
  border-radius: 10px;
}

.page-btn {
  margin-right: 10px;
}

.progress-bar {
  margin-bottom: 20px;
}
</style>
